import { mapState } from 'vuex';
import { getItem } from '@/api/api-methods';
import { addressUtilsMixin } from '../../../shared/mixins/addressUtilsMixin';
import { generalRepoMixin } from '../../../shared/mixins/generalRepoMixin';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import KnAddress from '../../../shared/components/KnAddress.vue';
import KnAutoComplete from '../../../shared/components/KnAutoComplete.vue';
import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnCheckBox from '../../../shared/components/KnCheckBox.vue';
import KnContactInfo from '../../../shared/components/KnContactInfo.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnPersonalInfo from '../../../shared/components/KnPersonalInfo.vue';
import KnTabs from '../../../shared/components/KnTabs.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import KnTaxInfo from '../../../shared/components/KnTaxInfo/KnTaxInfo.vue';
import {
  generateUsername,
  generatePassword,
  generateGenericUsername,
  generateGenericPassword,
} from '../../../shared/helpers/dataUtils';
import {
  fetchSupplierCategories,
  fetchSupplierSubcategories,
  postSupplier,
  putSupplier,
} from '../../helpers/suppliersOptions';
import {
  canAdd,
  canChange,
  insufficientPermissionsMessage,
} from '../../../shared/helpers/permissionsUtils';
export default {
  components: {
    KnPersonalInfo,
    KnFormSubtitle,
    KnFormActionButtons,
    KnTaxInfo,
    KnAddress,
    KnAutoComplete,
    KnCheckBox,
    KnContactInfo,
    KnLocalAlert,
    KnTabs,
    KnTextField,
    KnFormTitle,
    KnBackToTopButton,
    KnFormNoteOfMandatory,
  },
  mixins: [validationFormMixin, generalRepoMixin, addressUtilsMixin],
  props: {
    entity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tabs: [{ name: 'Información básica', value: 33 }],
      valueDeterminate: 33,
      routerName: 'Proveedores',
      resource: 'proveedor',
      supplierName: '',
      showPersonalInfo: false,
      showTaxInfo: false,
      supplierPersonalInfo: {
        id: null,
        primer_nombre: null,
        segundo_nombre: '',
        apellido_paterno: null,
        apellido_materno: null,
        fecha_nacimiento: null,
        edad: null,
        genero: null,
        numero_identidad_CURP: '',
        email: null,
        telefono_movil: null,
        institucion_educativa: null,
      },
      supplierAddress: {
        id: null,
        estado: null,
        ciudad: null,
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: '',
        numero_contacto: '',
      },
      supplierTaxInfo: {
        id: null,
        tax_id: null,
        nombre_o_razon_social: null,
        uso_factura: null,
        regimen_fiscal: '',
        moneda: '',
        lugar_expedicion: '',
        tipo_cfdi: '',
        condiciones_pago: '',
        forma_pago: '',
        metodo_pago: '',
        estatus_sistema: true,
      },
      supplierId: null,
      fillAddressFrom: false,
      foto_perfil: null,
      supplierPhoto: null,
      supplierCategories: [],
      supplierSubcategories: [],
      bkSupplierCategories: [],
      bkSupplierSubcategories: [],
      categories: [],
      subcategories: [],
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: 'info',
      /*********************** */
    };
  },
  async created() {
    this.loading = true;
    this.alertText = 'Cargando... Por favor, espera';
    this.alertType = 'info';
    this.showAlert = true;
    await this.fetchData();
    if (!this.isNewMode) {
      this.fillData();
    } else {
      this.supplierPersonalInfo.institucion_educativa = this.institutionId;
    }
    setTimeout(() => (this.showAlert = false), 1000);
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    title() {
      return this.entity === null ? 'Agregar proveedor' : 'Editar proveedor';
    },
    isNewMode() {
      return this.entity === null;
    },
    successAlertText() {
      return this.isNewMode
        ? 'Proveedor registrado con exito!'
        : 'Proveedor actualizado con exito!';
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info';
    },
    dynamicTabs() {
      if (this.showPersonalInfo && this.showTaxInfo) {
        return [
          { name: 'Información básica', value: 33, percentage: 33 },
          { name: 'Información representante', value: 66, percentage: 66 },
          { name: 'Datos fiscales', value: 100, percentage: 100 },
        ];
      } else if (this.showPersonalInfo) {
        return [
          { name: 'Información básica', value: 33, percentage: 50 },
          { name: 'Información representante', value: 66, percentage: 100 },
        ];
      } else if (this.showTaxInfo) {
        return [
          { name: 'Información básica', value: 33, percentage: 50 },
          { name: 'Datos fiscales', value: 100, percentage: 100 },
        ];
      }
      return [{ name: 'Información básica', value: 33, percentage: 100 }];
    },
    invalidFields() {
      //console.log('employeePersonalInfo tiene nulls?', this.objectHasNulls(this.employeePersonalInfo), this.employeePersonalInfo)
      //console.log('employeeAddress tiene nulls?', this.objectHasNulls(this.employeeAddress), this.employeeAddress)
      //console.log('employeeTaxInfo tiene nulls?', this.objectHasNulls(this.employeeTaxInfo), this.employeeTaxInfo)
      // return (
      //   this.isNewMode &&
      //   (this.objectHasNulls(this.supplierPersonalInfo) ||
      //     this.objectHasNulls(this.supplierAddress) ||
      //     this.objectHasNulls(this.supplierTaxInfo))
      // );
      return false;
    },
    isReadonly() {
      return this.isNewMode ? false : !canChange(this.resource);
    },
  },

  methods: {
    async fetchData() {
      const { data: categoriesResults } = await fetchSupplierCategories({
        institutionId: this.institutionId,
        systemStatus: true,
      });
      const { data: subcategoriesResults } = await fetchSupplierSubcategories({
        institutionId: this.institutionId,
        systemStatus: true,
      });
      this.categories = categoriesResults;
      this.subcategories = subcategoriesResults;
    },
    async createSupplier() {
      try {
        this.alertText = 'Creando proveedor...';
        /**
         * Los datos personales solo se van a crear si es
         * que se marca el checkbox para completar
         * datos personales
         */
        if (this.showPersonalInfo) {
          this.supplierAddress.numero_contacto =
            this.supplierPersonalInfo.telefono_movil;
          this.supplierAddress.institucion_educativa = this.institutionId;
          this.alertText = 'Creando dirección de proveedor';
          await this.postAddress(this.supplierAddress);
          // console.log('Direccion proveedor: ', this.supplierAddress);

          this.supplierPersonalInfo.institucion_educativa = this.institutionId;
          this.supplierPersonalInfo.direccion = this.supplierAddress.id;
          this.supplierPersonalInfo.telefono_casa =
            this.supplierPersonalInfo.telefono_movil;
          this.alertText = 'Creando datos personales de proveedor';
          // console.log('Datos personales: ', this.supplierPersonalInfo);
          await this.postPersonalInfo(this.supplierPersonalInfo);
          // console.log(
          //   'Datos personales proveedor: ',
          //   this.supplierPersonalInfo
          // );
        }

        /**
         * Los datos fiscales solo se van a crear si es
         * que se marca el checkbox para completar
         * datos fiscales
         */
        if (this.showTaxInfo) {
          this.alertText = 'Creando datos fiscales de proveedor';
          await this.postTaxInfo(this.supplierTaxInfo);
          //console.log('Datos fiscales proveedor: ', this.supplierTaxInfo);
        }

        let userName = '';
        let userPassword = '';
        let name = this.supplierPersonalInfo.primer_nombre;
        let lastName = this.supplierPersonalInfo.apellido_paterno;
        let email = '';

        if (this.showPersonalInfo) {
          userName = generateUsername(this.supplierPersonalInfo);
          userPassword = generatePassword(this.supplierPersonalInfo);
          email = this.supplierPersonalInfo.email;
        } else {
          const supplierName = this.separateStringInTwo(this.supplierName);
          userName = generateGenericUsername(supplierName, 'PROVEEDOR');
          userPassword = generateGenericPassword();
          name = supplierName.firstName;
          lastName = supplierName.lastName;
        }

        const userObj = {
          nombre_usuario: userName,
          password: userPassword,
          correo: email,
          nombre: name,
          apellido_paterno: lastName,
          id: null,
          is_staff: false,
        };
        this.alertText = 'Creando usuario de proveedor';
        await this.postUser(userObj);
        // console.log('Usuario proveedor: ', userObj);

        this.alertText = `Usuario proveedor: ${userObj.nombre_usuario} ${userObj.password}`;

        const supplierObj = {
          usuario: userObj.id,
          nombre_proveedor: this.supplierName,
          datos_personales: this.showPersonalInfo
            ? this.supplierPersonalInfo.id
            : null,
          datos_fiscales: this.showTaxInfo ? [this.supplierTaxInfo.id] : [],
          categorias: this.supplierCategories,
          sub_categorias: this.supplierSubcategories,
          institucion_educativa: this.institutionId,
          estatus_sistema: true,
        };
        this.alertText = 'Creando proveedor';
        const { ok, message } = await postSupplier(supplierObj);
        if (!ok) {
          this.errors.push(message);
        }
      } catch (error) {
        this.errors.push('Error al intentar crear proveedor. ' + error);
        console.error('Error al crear proveedor.', error);
      }
    },
    async updateSupplier() {
      try {
        this.alertText = 'Actualizando proveedor...';
        this.supplierAddress.numero_contacto =
          this.supplierPersonalInfo.telefono_movil;
        this.supplierAddress.institucion_educativa = this.institutionId;

        this.supplierPersonalInfo.institucion_educativa = this.institutionId;
        this.supplierPersonalInfo.direccion = this.supplierAddress.id;
        this.supplierPersonalInfo.telefono_casa =
          this.supplierPersonalInfo.telefono_movil;

        if (
          this.supplierAddress.id !== null &&
          this.supplierPersonalInfo.id !== null
        ) {
          this.alertText = 'Actualizando dirección de proveedor';
          // console.log('DirecciónId - Proveedor', this.supplierAddress);
          // const supplierAddressObj = {
          //   calle: this.supplierAddress.calle,
          //   numero_exterior: this.supplierAddress.numero_exterior,
          //   numero_interior: this.supplierAddress.numero_interior,
          //   colonia: this.supplierAddress.colonia,
          //   ciudad: this.supplierAddress.ciudad,
          //   estado: this.supplierAddress.estado,
          //   numero_contacto: this.supplierAddress.numero_contacto,
          //   codigo_postal: this.supplierAddress.codigo_postal,
          //   institucion_educativa: this.institutionId,
          // };
          await this.updateAddress(
            this.supplierAddress,
            this.supplierPersonalInfo.direccion
          );
          // console.log('Direccion proveedor: ', this.supplierAddress);
          // console.table('Direccion', this.supplierAddress);

          this.alertText = 'Actualizando datos personales de proveedor';
          await this.updatePersonalInfo(
            this.supplierPersonalInfo,
            this.supplierPersonalInfo.id
          );
        } else if (this.showPersonalInfo) {
          this.alertText = 'Creando dirección de proveedor';
          await this.postAddress(this.supplierAddress);

          this.supplierPersonalInfo.direccion = this.supplierAddress.id;
          this.alertText = 'Creando datos personales de proveedor';
          await this.postPersonalInfo(this.supplierPersonalInfo);
          // console.log(
          //   'Datos personales proveedor: ',
          //   this.supplierPersonalInfo
          // );
        }

        if (this.supplierTaxInfo.id !== null) {
          this.alertText = 'Actualizando datos fiscales de proveedor';
          await this.updateTaxInfo(
            this.supplierTaxInfo,
            this.supplierTaxInfo.id
          );
          // console.log('Datos fiscales proveedor: ', this.supplierTaxInfo);
        } else if (this.showTaxInfo) {
          this.alertText = 'Creando datos fiscales de proveedor';
          await this.postTaxInfo(this.supplierTaxInfo);
        }

        const supplierObj = {
          id: this.supplierId,
          usuario: this.entity.usuario.id,
          nombre_proveedor: this.supplierName,
          datos_personales: this.showPersonalInfo
            ? this.supplierPersonalInfo.id
            : null,
          datos_fiscales: this.showTaxInfo ? [this.supplierTaxInfo.id] : [],
          categorias: this.supplierCategories,
          sub_categorias: this.supplierSubcategories,
          institucion_educativa: this.institutionId,
          estatus_sistema: true,
        };
        this.alertText = 'Creando proveedor';
        const { ok, message } = await putSupplier(supplierObj);
        if (!ok) {
          this.errors.push(message);
        }
        // console.log('Proveedor: ', data);
      } catch (error) {
        this.errors.push('Error al intentar actualizar proveedor.' + error);
        console.error('Error al intentar actualizar proveedor.', error);
      }
    },

    async createObjects() {
      try {
        this.loading = true;
        this.alertText = 'Cargando...';
        this.showAlert = true;
        await this.createSupplier();

        if (this.errors.length) {
          this.alertType = 'error';
          this.alertText = this.errors.join(', ');
        } else {
          this.alertType = this.successAlertType;
          this.alertColor = 'success';
          this.alertText = this.successAlertText;
        }
        this.loading = false;
      } catch (error) {
        console.error(
          'Error al dar de alta Proveedor y objetos relacionados',
          error
        );
      }
    },
    async updateObjects() {
      try {
        this.loading = true;
        this.alertText = 'Cargando...';
        this.showAlert = true;
        await this.updateSupplier();

        this.loading = false;
        if (this.errors.length > 0) {
          this.alertType = 'error';
          this.alertText = this.errors.join(', ');
        } else {
          this.alertType = this.successAlertType;
          this.alertColor = 'success';
          this.alertText = this.successAlertText;
        }
      } catch (error) {
        this.loading = false;
        // console.log(
        //   'Error al actualizar Proveedor y objetos relacionados',
        //   error
        // );
        this.errors.push(
          'Error al actualizar Proveedor y objetos relacionados'
        );
        this.alertType = 'error';
        this.alertText = this.errors.join(', ');
      }
    },
    setTabValue(val) {
      this.valueDeterminate = val;
    },
    async save() {
      if (this.isNewMode) {
        if (canAdd(this.resource)) {
          this.createObjects();
        } else {
          this.insufficientPermissionAlert();
        }
      } else {
        if (canChange(this.resource)) {
          this.updateObjects();
        } else {
          this.insufficientPermissionAlert();
        }
      }
    },
    insufficientPermissionAlert() {
      this.alertType = 'info';
      this.alertText = insufficientPermissionsMessage();
      this.alertColor = 'warning';
      this.loading = false;
      this.showAlert = true;
    },
    cancel() {
      this.returnToTable();
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.clean();
      this.closeAlert();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    clean() {
      window.scroll({
        top: 50,
        left: 0,
        behavior: 'smooth',
      });
      this.valueDeterminate = 33;
      this.supplierPersonalInfo = {
        primer_nombre: null,
        segundo_nombre: '',
        apellido_paterno: null,
        apellido_materno: null,
        fecha_nacimiento: null,
        edad: null,
        genero: null,
        numero_identidad_CURP: '',
        email: null,
        telefono_movil: null,
        institucion_educativa: this.institutionId,
      };
      this.supplierAddress = {
        estado: null,
        ciudad: null,
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: '',
        numero_contacto: '',
      };
      this.supplierTaxInfo = {
        tax_id: null,
        nombre_o_razon_social: null,
        uso_factura: null,
        regimen_fiscal: '',
        moneda: '',
        lugar_expedicion: '',
        tipo_cfdi: '',
        condiciones_pago: '',
        forma_pago: '',
        metodo_pago: '',
        estatus_sistema: true,
      };
      this.supplierId = null;
    },
    async fillData() {
      // console.log('Datos Personales', this.supplierPersonalInfo);
      this.supplierId = this.entity.id;
      this.supplierName = this.entity.nombre_proveedor;
      if (this.entity.datos_personales !== null) {
        this.supplierPersonalInfo = { ...this.entity.datos_personales };
        this.supplierPersonalInfo.institucion_educativa =
          this.entity.institucion_educativa.id;
        this.fillAddressFrom = false;
        const supplierAddress = await getItem(
          `/app-personas/direccion/${this.supplierPersonalInfo.direccion}`
        );
        this.supplierAddress = { ...supplierAddress };
        // console.log('Direccion proveedor', supplierAddress);
        this.supplierAddress.codigo_postal = supplierAddress.codigo_postal.id;
        this.supplierAddress.code = supplierAddress.codigo_postal.codigo_postal;
        this.supplierAddress.municipio =
          supplierAddress.codigo_postal.nombre_municipio;
        this.supplierPersonalInfo.institucion_educativa =
          this.entity.institucion_educativa;
        this.fillAddressFrom = true;

        this.showPersonalInfo = true;
      }
      if (this.entity.datos_fiscales.length > 0) {
        this.supplierTaxInfo = { ...this.entity.datos_fiscales[0] };
        this.showTaxInfo = true;
      }

      this.supplierCategories = this.entity.categorias.map(
        (category) => category.id
      );
      this.supplierSubcategories = this.entity.sub_categorias.map(
        (subcategory) => subcategory.id
      );
    },
    /** Metodos para foto **/
    savePhoto(file) {
      this.foto_perfil = file;
    },
    clearPhoto() {
      this.foto_perfil = null;
    },
    clearImage() {
      this.supplierPhoto = null;
      this.supplierPersonalInfo.url_foto = '';
    },
    separateStringInTwo(initialString) {
      const splitedString = initialString.split(' ');
      const numberOfWords = splitedString.length;
      let mediumValue = 0;
      let item = {
        firstName: '',
        lastName: '',
      };
      if (numberOfWords === 1) {
        item.firstName = splitedString[0];
      }
      if (numberOfWords >= 2) {
        mediumValue =
          numberOfWords % 2 === 0 ? numberOfWords / 2 : numberOfWords / 2 + 1;
        item.firstName = splitedString.slice(0, mediumValue).join(' ');
        item.lastName = splitedString
          .slice(mediumValue, numberOfWords)
          .join(' ');
      }
      return item;
    },
  },
};
